import * as Sentry from '@sentry/nextjs';

import { PUBLIC_EVENT_PATH } from '@hl-portals/constants';

export function usePublicEvent<Event>(source?: string) {
  async function recordPublicEvent(event: Event = {} as Event) {
    try {
      const payload = {
        source,
        ...event,
      };

      fetch(PUBLIC_EVENT_PATH, {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: payload ? JSON.stringify(payload) : null,
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  return { recordPublicEvent };
}
