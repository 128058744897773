import * as Sentry from '@sentry/nextjs';

import { UseMutationOptions, useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { http } from '@hl-portals/libs/http';
import { SnapshotAttributes, SnapshotResponse } from '@hl-portals/libs/types';

import { CREATE_SNAPSHOT_PATH } from '@hl-portals/constants';

export type SnapshotType = 'LenderLeadSnapshot';

export const CREATE_SNAPSHOT_ERROR = 'Unable to create snapshot';

export function useCreateSnapshot<Payload, ResponseData>(
  options?: UseMutationOptions<
    SnapshotAttributes<ResponseData>,
    unknown,
    Payload
  >
) {
  return useMutation(
    async (payload: Payload, type: SnapshotType = 'LenderLeadSnapshot') => {
      const res = await http.public.post<SnapshotResponse<ResponseData>>(
        CREATE_SNAPSHOT_PATH,
        {
          type,
          payload,
        }
      );
      return res.data?.data?.attributes;
    },
    {
      ...options,
      onError: (...args) => {
        options?.onError?.(...args);
        toast(CREATE_SNAPSHOT_ERROR, { type: 'error' });
        Sentry.captureException(args[0]);
      },
    }
  );
}
