import { useQuery } from 'react-query';

import { http } from '@hl-portals/libs/http';

import { FETCH_WHITE_LABEL_STYLE } from '@hl-portals/constants';

type LenderBrandResponse = {
  primary_color: string;
  secondary_color: string;
  logo_url: string;
};

export const usePartnerBrand = (partnerSlug: string) =>
  useQuery({
    queryKey: `PARTNER_BRAND_${partnerSlug}`,
    queryFn: async () => {
      const res = await http.public.get<LenderBrandResponse>(
        FETCH_WHITE_LABEL_STYLE(partnerSlug)
      );
      return res.data;
    },
    enabled: !!partnerSlug,
  });
