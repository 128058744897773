import * as Sentry from '@sentry/nextjs';
import { useFlags } from 'flagsmith/react';

import { UseQueryOptions, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { useModal } from '@hl-portals/hooks';

import { CalculateEquityModal } from './CalculateEquityModal';
import {
  CalculateEquityAgentArgs,
  calculateAgentEquity,
} from './calculate-agent-equity';
import {
  CalculateEquityClientArgs,
  calculateClientEquity,
} from './calculate-client-equity';
import {
  CalculateEquityLenderArgs,
  calculateLenderEquity,
} from './calculate-lender-equity';

export type CalculateEquityData = {
  agent_fee: number;
  bbys_fee: number;
  closing_cost: number;
  estimated_loan_payoff: number;
  fallback: boolean;
  high_end_range: number;
  id: string;
  inspection_cost: number;
  low_end_range: number;
  valid_equity: boolean;
};

export type CalculateEquityError = {
  response: {
    data: {
      errors: {
        hl_no_data: string;
        hc_fetch_fail: string;
        hc_no_data: string;
        hc_no_ltv_data: string;
        hc_no_mortgage_data: string;
        hc_no_property_value_data: string;
      };
    };
  };
};

type CalculateEquityOptions = {
  onConfirm?: (result: CalculateEquityData) => void;
  disableModal?: boolean;
} & UseQueryOptions<CalculateEquityData, CalculateEquityError>;

export const USE_CALCULATE_EQUITY_QUERY_KEY = 'USE_CALCULATE_EQUITY_QUERY_KEY';

export const CALCULATE_EQUITY_ERROR_MESSAGES = {
  MORTGAGE:
    "We don't have data on the mortgage balance for this property, please provide an estimate to continue",
  NO_DATA:
    "We're sorry, we don’t have data on this property. Please try a different address",
};

export const useCalculateAgentEquity = (
  args: CalculateEquityAgentArgs,
  options?: CalculateEquityOptions
) => {
  const { openModal } = useModal();

  const {
    'bbys-euc-fallback-calculation': { enabled: eucFallbackEnabled },
  } = useFlags(['bbys-euc-fallback-calculation']);

  return useQuery<CalculateEquityData, CalculateEquityError>(
    [USE_CALCULATE_EQUITY_QUERY_KEY, args],
    ({ signal }) => calculateAgentEquity(args, signal!, eucFallbackEnabled),
    {
      ...options,
      onSuccess: (result) => {
        if (!result.valid_equity) {
          openModal(
            <CalculateEquityModal
              onConfirm={() => options?.onConfirm && options.onConfirm(result)}
            />
          );
          return;
        }
        if (options?.onSuccess) {
          options.onSuccess(result);
        }
      },
    }
  );
};

export const useCalculateLenderEquity = (
  args: CalculateEquityLenderArgs,
  options?: CalculateEquityOptions
) => {
  const { openModal } = useModal();

  return useQuery<CalculateEquityData, CalculateEquityError>(
    [USE_CALCULATE_EQUITY_QUERY_KEY, args],
    () => calculateLenderEquity(args),
    {
      ...options,
      onSuccess: (result) => {
        if (!result.valid_equity && !options?.disableModal) {
          openModal(
            <CalculateEquityModal
              onConfirm={() => {
                if (options?.onConfirm) {
                  options.onConfirm(result);
                }
                if (options?.onSuccess) {
                  options.onSuccess(result);
                }
              }}
            />
          );
          return;
        }
        if (options?.onSuccess) {
          options.onSuccess(result);
        }
      },
      onError: (...errorArgs) => {
        options?.onError?.(...errorArgs);
        toast('Unable to calculate equity', { type: 'error' });
        Sentry.captureException(errorArgs[0]);
      },
    }
  );
};

export const useCalculateClientEquity = (
  args: CalculateEquityClientArgs,
  options?: CalculateEquityOptions
) => {
  const { openModal } = useModal();

  return useQuery<CalculateEquityData, CalculateEquityError>(
    [USE_CALCULATE_EQUITY_QUERY_KEY, args],
    () => calculateClientEquity(args),
    {
      ...options,
      onSuccess: (result) => {
        if (!result.valid_equity) {
          openModal(
            <CalculateEquityModal
              onConfirm={() => options?.onConfirm && options.onConfirm(result)}
            />
          );
          return;
        }
        if (options?.onSuccess) {
          options.onSuccess(result);
        }
      },
    }
  );
};

export { CalculateEquityModal };
