import * as Sentry from '@sentry/nextjs';

import { UseQueryOptions, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { http } from '@hl-portals/libs/http';

import { STATE_ELIGIBILITY_PATH } from '@hl-portals/constants';

export type UseStateEligibilityCheckData = {
  is_eligible: boolean;
};

type UseStateEligibilityOptions = UseQueryOptions<UseStateEligibilityCheckData>;

export const UseStateEligibilityQueryKey = 'STATE_ELIGIBILITY';

export const STATE_ELIGIBILITY_ERROR = (state: string) =>
  `Unable to check eligibility for state: ${state}`;

export const useStateEligibilityCheck = (
  state: string,
  options?: UseStateEligibilityOptions
) =>
  useQuery<UseStateEligibilityCheckData>(
    [UseStateEligibilityQueryKey, state],
    async () => {
      const res = await http.public.get(STATE_ELIGIBILITY_PATH(state));
      return res.data;
    },
    {
      ...options,
      onError: (...args) => {
        options?.onError?.(...args);
        toast(STATE_ELIGIBILITY_ERROR(state), { type: 'error' });
        Sentry.captureException(args[0]);
      },
    }
  );
