import * as Sentry from '@sentry/nextjs';

import { UseQueryOptions, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { http } from '@hl-portals/libs/http';

import {
  GET_PROPERTY_UUID,
  GET_PROPERTY_UUID_PUBLIC,
} from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

type PropertyUUIDResponse = {
  uuid: string;
};

export const USE_PROPERTY_UUID_QUERY_KEY = 'USE_PROPERTY_UUID_QUERY_KEY';

export const FETCH_PROPERTY_UUID_ERROR = (address: string) =>
  `Unable to fetch the property uuid for address: ${address}`;

export const usePropertyUUID = (
  fullAddress: string,
  options?: UseQueryOptions<string> & {
    isPublic?: boolean;
  }
) => {
  const encodedAddress = encodeURIComponent(fullAddress);
  const propertyUUIDUrl = GET_PROPERTY_UUID(encodedAddress);
  const propertyUUIDPublicUrl = GET_PROPERTY_UUID_PUBLIC(encodedAddress);

  return useQuery<string>(
    [USE_PROPERTY_UUID_QUERY_KEY, fullAddress],
    async ({ signal }) => {
      if (options?.isPublic) {
        const {
          data: {
            data: {
              attributes: { uuid },
            },
          },
        } = await http.public.get(propertyUUIDPublicUrl);

        return uuid;
      } else {
        const { uuid } = await enhancedAxios<PropertyUUIDResponse>({
          url: options?.isPublic ? propertyUUIDPublicUrl : propertyUUIDUrl,
          translateJsona: true,
          signal,
        });
        return uuid;
      }
    },
    {
      ...options,
      onError: (error) => {
        Sentry.captureException(error);
        toast(FETCH_PROPERTY_UUID_ERROR(fullAddress), { type: 'error' });

        if (options?.onError) {
          options.onError(error);
        }
      },
    }
  );
};
